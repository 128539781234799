import RebateHeader from '@/components/rebate/rebate-header/index.vue'
import Rmb from '@/components/common/rmb/index.vue'
import IndexTabbar from '@/components/common/index-tabbar/index.vue'
import { SHILISHOU_REBATE_MAP } from '@/constants/rebate'
import { getRebateSumInfo } from '@/service/rebate/index'
import { getStartedAt, getEndedAt, getDate } from '@/utils/common'
import { getToken } from '@/utils/localStorage'
import { mapState } from 'vuex'

export default {
  name: 'rebate-sum',
  components: {
    RebateHeader,
    Rmb,
    IndexTabbar,
  },
  data () {
    return {
      SHILISHOU_REBATE_MAP,
      selectedDate: new Date(),
      rebateInfo: {
        teamComplianceDiscount: 1999,  //  团队达标折扣
        referralBonuses: 9919,  //  推荐奖励 战略(直邀战略*9%+间邀战略*4.5%)  联创(直邀联创*6%+间邀联创*3%)
        personalDiscountReward: 92299,  //  联创及战略 个人折扣奖励 团队销售折扣+推荐奖励+团队达标折扣
        discountDeduction: 892299,  //  折扣抵扣 团队销售折扣-（直招的联创支出+直招战略支出）
        teamDiscount: 12222, // 下属团队销售折扣 旗下战略订货数*当前产品差价+旗下联创订货数*当前产品差价
        teamTotalSale: 12662, // 下属团队总销量额 旗下所有的代理当月进货数*产品单价
        teamProfit: 12882, // 团队净利润 团队销售折扣-(直招的联创支出+直招战略支出)
        user: {
          id: '0001',
          name: '吴亦凡',
          headImg: 'https://tse1-mm.cn.bing.net/th/id/OIP.9finJW2__HweWnazL7vX1QAAAA?w=181&h=181&c=7&o=5&pid=1.7',
          levelName: '股东',
          level: 2
        }
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  watch: {
    //  监听日期的变化，日期变化改变路由
    selectedDate (newValue, oldValue) {
      if (newValue.getTime() !== oldValue.getTime()) {
        const $route = this.$route
        const startTime = getStartedAt(newValue)
        const endTime = getEndedAt(newValue)
        //  改变路由
        this.$router.push({ ...$route, query: { startTime, endTime } })
        //  重新请求
        this.init()
      }
    }
  },
  methods: {
    async init () {
      this.reset()
      const token = getToken()
      const { selectedDate } = this
      const startTime = getStartedAt(selectedDate)
      const endTime = getEndedAt(selectedDate)
      const rebateInfo = await getRebateSumInfo({ startTime, endTime, token })
      this.rebateInfo = rebateInfo
    },
    reset () {
      const { user } = this
      const level = user.levelall && user.levelall[0] && user.levelall[0].levelId
      const initRebateInfo = {
        teamComplianceDiscount: '--',
        referralBonuses: '--',
        personalDiscountReward: '--',
        discountDeduction: '--',
        teamDiscount: '--',
        teamTotalSale: '--',
        teamProfit: '--',
        user: {
          id: '--',
          name: '--',
          headImg: '',
          levelName: '--',
          level
        }
      }
      this.rebateInfo = initRebateInfo
    }
  },
  created () {
    const startTime = this.$route.query.startTime
    if (startTime) {
      this.selectedDate = getDate(startTime)
    } else {
      this.init()
    }
  },
}